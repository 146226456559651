import { render, staticRenderFns } from "./ContraView.vue?vue&type=template&id=040c5305&scoped=true"
import script from "./ContraView.vue?vue&type=script&lang=js"
export * from "./ContraView.vue?vue&type=script&lang=js"
import style0 from "./ContraView.vue?vue&type=style&index=0&id=040c5305&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "040c5305",
  null
  
)

export default component.exports